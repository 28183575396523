export default {
  env: import.meta.env.NODE_ENV,
  mode: import.meta.env.VITE_MODE,
  apiToken: import.meta.env.VITE_API_TOKEN,
  esApiUrl: import.meta.env.VITE_ES_API_URL,
  bohV2Url: import.meta.env.VITE_BOH_V2_URL,
  version: import.meta.env.VITE_VERSION || 'unknown',
  sentryVersion: import.meta.env.SENTRY_VERSION,
  customerPortal: import.meta.env.VITE_CUSTOMER_PORTAL_URL,
  adobeEmbedKey: import.meta.env.VITE_ADOBE_EMBED_KEY,
  amplitudeKey: import.meta.env.VITE_AMPLITUDE_KEY,
  amplitudeEnabled: import.meta.env.VITE_AMPLITUDE_KEY != null,
  froalaKey: import.meta.env.VITE_FROALA_KEY,
};
