import { defineStore, getActivePinia } from 'pinia';
import { useSnackbarStore } from '@/stores/snackbarStore';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    priorities: ['Low', 'Medium', 'High', 'Emergency'],
    contactMethods: ['Call', 'Email', 'Text', 'Slack'],
    priorityDefs:
      `Emergency - Critical asset or infrastructure that needs to be operational within hours. OT, weekend rates are approved if needed to resolve issue as quickly as possible 4 hours. (Ex Walkin cooler where it is not brought back to temp quickly may spoil food)\n
        High - Important piece of asset or infrastructure that when down causes business interruptions. Needs to be operational within 24 hours. OT, weekend rates may be needed to resolve issue in a timely manner. (Ex Oven that handles high volume that needs to be operational to avoid long service times)\n
        Medium - Asset or infrastructure that is not mission critical. Ok to dispatch for first available service time that is not on OT or weekend rates. Unit to be operational within 48-72 hours. (Ex Reach in cooler where product can be moved to an alternate cooler. Unit being down is more on an inconvenience and affecting operations)\n
        Low - Asset or infrastructure that can be bundled with other requests when available. Dispatch is not needed right away. Typically locations just want to get it on the list. Issue resolved in over 72 hours. (Ex Sink has a slow drip and can wait to be bundled for the next time a handyman or plumber are dispatched for another service request)`.replace(
        /(?:\r\n|\r|\n)/g,
        '<br>',
      ),
  }),
  actions: {
    resetState() {
      const pinia = getActivePinia();
      // eslint-disable-next-line no-underscore-dangle
      pinia._s.forEach((store) => store.$reset());
    },
    copyToClipboard(thingToCopy) {
      const snackbarStore = useSnackbarStore();
      navigator.clipboard.writeText(thingToCopy);
      snackbarStore.customMessage(`${thingToCopy} was saved to clipboard.`);
    },
  },
});
